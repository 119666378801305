var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "boxedCopy" }, [
      _vm._v("\n    " + _vm._s(this.homeCopy.boxCopy1) + "\n    "),
      _c("b", [_vm._v(_vm._s(this.homeCopy.boxCopy2))]),
    ]),
    _c(
      "div",
      {
        staticClass: "imgHeader",
        style: {
          backgroundImage:
            "linear-gradient(to bottom right, rgba(0,0,0,0.8), rgba(0,0,0,0.2))," +
            "url(" +
            require("../assets/images/homeHeader.png") +
            ")",
        },
      },
      [
        _c("BrandStorySubmissionContainer"),
        _c("div", { staticClass: "scroll-tenents" }, [
          _c(
            "div",
            [
              _c("font-awesome-icon", {
                staticClass: "icon left",
                attrs: { icon: ["fas", "caret-left"] },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "ellipsis left" }, [_vm._v("...")]),
          _c("div", [_vm._v("Scroll Tenents")]),
          _c("div", { staticClass: "ellipsis right" }, [_vm._v("...")]),
          _c(
            "div",
            [
              _c("font-awesome-icon", {
                staticClass: "icon right",
                attrs: { icon: ["fas", "caret-right"] },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tenetSelector" },
      [
        _c("LoadingOverlay", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.fetchingData,
              expression: "fetchingData",
            },
          ],
        }),
        _c(
          "div",
          { staticClass: "containerDropDownBtn" },
          _vm._l(this.tenets, function (tenet, index) {
            return _c("DropDownBtn", {
              key: "tenet" + tenet.id,
              class: { last: index === _vm.tenets.length - 1 },
              attrs: {
                id: tenet.id,
                name: tenet.name,
                dropDownItems: _vm.businessAreas,
              },
            })
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content-wrapper" },
      [
        _c(
          "div",
          { staticClass: "carousel-wrapper" },
          [
            _c("div", { staticClass: "newNoteTitle" }, [
              _vm._v(_vm._s(this.homeCopy.subHeaderTitle)),
            ]),
            _c("Carousel", { staticClass: "carousel" }),
          ],
          1
        ),
        _c("a", { attrs: { id: "tenetAnchor" } }),
        _c("div", { staticClass: "tenetFilter" }, [
          _vm._v(_vm._s(_vm.selectedTenet)),
        ]),
        _c("BrandStoriesList", { staticClass: "brandStories" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }