var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", [
    _vm.label
      ? _c("span", [
          _vm._v("\n    " + _vm._s(this.label)),
          _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        ])
      : _vm._e(),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selected,
            expression: "selected",
          },
        ],
        attrs: {
          name: _vm.name,
          required: _vm.required,
          disabled: _vm.disabled,
        },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.handleChange,
          ],
        },
      },
      _vm._l(_vm.selectOptions, function (selectOption, index) {
        return _c(
          "option",
          {
            key: "selectOption" + index,
            domProps: { value: selectOption.value },
          },
          [_vm._v("\n      " + _vm._s(selectOption.copy) + "\n    ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }