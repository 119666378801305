var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [_vm._v("Resource Videos")]),
      _c("SelectElem", {
        attrs: {
          name: "resourceVideo",
          id: "resourceVideoSelect",
          selectOptions: _vm.resourceVideos,
        },
        on: { input: _vm.handleChange },
        model: {
          value: _vm.resourceVideoIdValue,
          callback: function ($$v) {
            _vm.resourceVideoIdValue = $$v
          },
          expression: "resourceVideoIdValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }